import { createSelector } from '@reduxjs/toolkit';
import {
  HubHeader,
  HubSector,
  BookingFormType,
  BookingRegistrationName,
  UploadedImage,
} from '../../../types';
import { DAY_MONTH, MONTH_DAY } from '../../../constants';
import { CoursePricingHeaderOption } from '../../../components/paymentJourneyRedesign/store/features/courseOptions/types';

export enum MobileLayouts {
  'TOP' = 'TOP',
  'BOTTOM' = 'BOTTOM',
}

export interface SiteThumbnail {
  backgroundImageUrl?: UploadedImage['path'];
  whiteLogoUrl: UploadedImage['path'];
}

export interface BookingForm {
  bookingFormType: BookingFormType;
  customBookingUrl: string;
  buttonText: string;
  mobileButtonText: string;
  displayStandartView: true;
  marketingSegments: string[];
  registrationOpen: true;
  displayNavBarCTA: true;
}

interface HomeHeroBanner {
  valueProposition: string;
}

export interface SiteHeader {
  data: {
    title: string;
    navBarLogoEnabled: boolean;
    hideInformaBar: boolean;
    scrollToTopEnabled: boolean;
    mobileLayout: MobileLayouts;
    partneringOne: boolean;
    bookingMetaTitle?: string;
    bookingMetaDescription?: string;
    bookingHideFromSearchEngines?: boolean;
    emailUpdatesEnabled: boolean;
    sapProductCode: string;
    packageThumbnail: SiteThumbnail;
    booking: boolean;
    fullScreen: boolean;
    startDate?: string;
    endDate?: string;
    dateFormat: typeof DAY_MONTH | typeof MONTH_DAY;
    venueCity?: string;
    venueName?: string;
    venueCountry?: string;
    formattedDate?: string;
    shortInfo?: string;
    homeHeroBanner: HomeHeroBanner;
    courseCode?: string;
    courseOptions?: CoursePricingHeaderOption[];
    breadcrumbs: Array<{
      name: string;
      path: string;
      tenant: string;
    }>;
    breadcrumbEnabled: boolean;
    brandHomeMenu: {
      header: HubHeader;
      sectors: HubSector[];
      theme: string;
    };
    bookingForm: BookingForm;
    registrationName: BookingRegistrationName;
    partneringOneEventId: string;
    logo?: {
      path: string;
    };
  };
  meta: {
    title: string;
    description: string;
    googleAnalyticsId: string;
  };
}

export const selectSiteHeader = createSelector(
  [(state) => state.siteHeader as SiteHeader],
  (data) => data,
);

export const selectSiteHeaderData = createSelector(
  [(state) => state.siteHeader as SiteHeader],
  (data) => data.data,
);
