import React from 'react';
import styled, { css } from 'styled-components';
import { color } from '../../styles/informatech/colours/colours';
import { typography } from '../../styles/informatech/typography/typography';
import { breakpoint } from '../../styles/shared';

const Logo = styled.img`
  margin-top: 35px;
  margin-bottom: 35px;
  align-self: flex-start;
  ${(props) =>
    props.logoType === 'techlogo' &&
    css`
      width: 240px;
      height: 60px;
    `}
  ${(props) =>
    props.logoType === 'marketslogo' &&
    css`
      width: 250px;
      height: auto;
    `}

  @media (max-width: ${breakpoint.tabletPlusBreakpoint}) {
    margin-top: 40px;
    margin-bottom: 60px;
  }
`;

const Prefix = styled.span`
  font-family: ${typography.type.primary};
  font-size: ${typography.size.s1}px;
  color: ${color.lightest};
  display: inline-block;
  position: absolute;
  top: 30px;
  left: 71px;

  @media (max-width: ${breakpoint.tabletPlusBreakpoint}) {
    top: 40px;
  }
`;

const LogoWrap = styled.a`
  position: relative;
  display: inline-block;
`;

const TenantLogo = ({ logoObj }) => {
  const { link, prefix, image, alt, logoType } = logoObj;
  return (
    <LogoWrap href={link}>
      {prefix ? <Prefix>Brought to you by</Prefix> : null}
      <Logo src={image} alt={alt} logoType={logoType} />
    </LogoWrap>
  );
};

export { TenantLogo };
