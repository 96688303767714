import React from 'react';
import { useRootSelector } from '../../../../../../store/hooks';
import {
  selectSiteHeader,
  SiteHeader,
} from '../../../../../../store/features/siteHeader';
import loadable from '@loadable/component';
import styles from './BrandHomeMenu.module.scss';
import { useDeviceDetect } from '../../../../../../hooks';

const Nav = loadable(
  () =>
    import(/* webpackChunkName: "HubNav" */ '../../../../../hub/shared/Nav'),
);

const MobileNav = loadable(
  () =>
    import(
      /* webpackChunkName: "HubMobileNav" */ '../../../../../hub/shared/MobileNav'
    ),
);

const ClassicHeader = loadable(
  () =>
    import(
      /* webpackChunkName: "ClassicHeader" */ '../../../../../headers/ClassicHeader'
    ),
);

export default function BreadHomeMenu() {
  const {
    data: { brandHomeMenu, fullScreen },
  } = useRootSelector(selectSiteHeader);
  const { isDesktop } = useDeviceDetect();

  const { header, sectors } =
    brandHomeMenu as SiteHeader['data']['brandHomeMenu'];

  const { navBarItems, headerNavBar } = header;

  const {
    brandLogo,
    disableNavbarHomeLink,
    displayBrandLogoInNavbar,
    displayKeywordSearch,
    displayTextContent,
    searchDisplayPlace,
    searchPlaceholderText,
    textContent,
  } = headerNavBar || {};

  const logoInHeader = !displayBrandLogoInNavbar;
  const searchInHeader =
    displayKeywordSearch && searchDisplayPlace === 'HEADER';
  const textContentInHeader = displayTextContent && !!textContent;
  const headerDisplayed = logoInHeader || searchInHeader || textContentInHeader;

  return (
    <div data-testid="brand-home-menu" className={styles.wrapper}>
      {headerDisplayed ? (
        <ClassicHeader
          logo={brandLogo}
          logoAlt="Brand Logo"
          displayBrandLogo={!displayBrandLogoInNavbar}
          displaySearch={
            displayKeywordSearch && searchDisplayPlace === 'HEADER'
          }
          displayMobileSearchButton={displayKeywordSearch}
          searchPlaceholderText={searchPlaceholderText}
          displayTextContent={displayTextContent}
          textContent={textContent}
          mobileNavBarContent={
            <Nav
              links={navBarItems}
              sectors={sectors}
              displayHomeLink={!disableNavbarHomeLink}
              displayKeywordSearch={displayKeywordSearch}
              searchPlaceholderText={searchPlaceholderText}
            />
          }
          fullScreen={fullScreen}
        />
      ) : (
        <MobileNav header={header} sectors={sectors} brandLogo={brandLogo} />
      )}
      {isDesktop && (
        <Nav
          links={navBarItems}
          sectors={sectors}
          displayHomeLink={!disableNavbarHomeLink}
          brandLogo={brandLogo}
          displayBrandLogo={displayBrandLogoInNavbar}
          displayKeywordSearch={
            displayKeywordSearch && searchDisplayPlace === 'NAVBAR'
          }
          searchPlaceholderText={searchPlaceholderText}
          displayUser={!headerDisplayed}
          fullScreen={fullScreen}
        />
      )}
    </div>
  );
}
