import {
  PricingProduct,
  PricingProducts,
  PricingProductType,
} from '../../../store/features/products/types';
import { getRelatedPrimaryProductsData } from '../getRelatedPrimaryProducts';
import { PricingProductSelectionTypes } from '../../../types/enums';
import { getRelatedSecondaryProducts } from '../getRelatedSecondaryProducts';
import { UniqueIdentifier } from '../../../../../types';
import {
  PackageFormValues,
  PricingPackagesFormValues,
} from '../../../store/features/packages/types';
import { CoursePricingOption } from '../../../store/features/courseOptions/types';

export interface GetPackageFormDefaultValuesParams {
  productsData: PricingProducts;
  courseOptions?: CoursePricingOption[];
  delegateTypeId?: UniqueIdentifier;
  siteOptionIndex?: number;
  vipCode?: string;
  selectedPrimaryProduct?: PricingProduct;
}

export function getPackageFormDefaultValues(
  params: GetPackageFormDefaultValuesParams,
) {
  const {
    productsData: {
      productTypesWithProducts,
      optionalProductTypesWithProducts,
      delegateTypes,
    },
    courseOptions,
    delegateTypeId,
    siteOptionIndex,
    vipCode,
    selectedPrimaryProduct,
  } = params;

  const primaryProduct =
    selectedPrimaryProduct ||
    findFirstPreselectedPrimaryProduct(productTypesWithProducts);
  const defaultSiteOptionIndex =
    siteOptionIndex || courseOptions?.find((option) => option.enabled)?.index;
  const defaultDelegateTypeId =
    delegateTypeId || delegateTypes[0]?.delegateTypeId;

  const defaultValues: PricingPackagesFormValues = {
    quantity: 1,
    delegateTypeId: defaultDelegateTypeId,
    siteOptionIndex: defaultSiteOptionIndex,
    vipCode,
    packageFormValues: {
      productMultipleIds: [],
      productSingleIdsMap: {},
      optionsMultipleMap: {},
      optionsSingleMap: {},
    },
  };

  if (primaryProduct?.id) {
    const preselectedPackageFormValues = getPreselectedPackageFormValues(
      primaryProduct,
      productTypesWithProducts,
      optionalProductTypesWithProducts,
    );

    defaultValues.packageFormValues = {
      ...defaultValues.packageFormValues,
      ...preselectedPackageFormValues,
    };
  }

  return defaultValues;
}

export function processPreselectedProductOptions(
  product: PricingProduct,
  optionsMultipleMap: PackageFormValues['optionsMultipleMap'],
  optionsSingleMap: PackageFormValues['optionsSingleMap'],
) {
  if (!product?.productOptionsList) {
    return;
  }

  product.productOptionsList.forEach((productOptionGroup, index) => {
    const isMultipleChoice =
      productOptionGroup.type === PricingProductSelectionTypes.MULTIPLE_CHOICE;
    const productIdWithIndex = `${product.id}-${index}`;
    const firstOption = productOptionGroup.options[0]?.id;
    const preselectedOptions = productOptionGroup.options.filter(
      (option) => option.preselected,
    );

    const optionsMap = isMultipleChoice ? optionsMultipleMap : optionsSingleMap;

    if (isMultipleChoice && preselectedOptions.length > 0) {
      optionsMap[productIdWithIndex] = preselectedOptions.map(
        (option) => option.id,
      );
    }

    if (!isMultipleChoice && firstOption) {
      // Single choice options don't have preselected values on admin side, so we should add always first option as preselected
      optionsMap[productIdWithIndex] = firstOption;
    }
  });
}

export function getAllProductsFromProductType(
  productTypes: PricingProductType[],
) {
  return productTypes.flatMap((productType: PricingProductType) => {
    if (!productType?.products?.length) return [];

    return productType.products;
  });
}

export function findFirstPreselectedPrimaryProduct(
  productTypesWithProducts: PricingProductType[],
) {
  const allPrimaryProducts = getAllProductsFromProductType(
    productTypesWithProducts,
  );

  return (
    allPrimaryProducts.find((product) => product.preselected) ||
    ({} as PricingProduct)
  );
}

export function getPreselectedProductsIds(products: PricingProduct[]) {
  return products
    .filter((product) => product.preselected)
    ?.map((preselectedProduct) => preselectedProduct.id);
}

export function getSingleProductIdsList(
  productSingleIdsMap: PackageFormValues['productSingleIdsMap'] = {},
) {
  return Object.values(productSingleIdsMap).filter(
    (id): id is UniqueIdentifier => !!id,
  );
}

export function getPreselectedPackageFormValues(
  selectedPrimaryProduct: PricingProduct,
  productTypesWithProducts: PricingProductType[],
  optionalProductTypesWithProducts: PricingProductType[],
) {
  const { products: relatedPrimaryProducts } = getRelatedPrimaryProductsData(
    productTypesWithProducts,
    selectedPrimaryProduct.productTypeId,
    selectedPrimaryProduct.id,
  );
  const relatedPrimaryProductsIds = getPreselectedProductsIds(
    relatedPrimaryProducts,
  );

  const productMultipleIds: PackageFormValues['productMultipleIds'] = [
    selectedPrimaryProduct.id,
    ...relatedPrimaryProductsIds,
  ];
  const productSingleIdsMap: PackageFormValues['productSingleIdsMap'] = {};
  const optionsMultipleMap: PackageFormValues['optionsMultipleMap'] = {};
  const optionsSingleMap: PackageFormValues['optionsSingleMap'] = {};

  [selectedPrimaryProduct, ...relatedPrimaryProducts].forEach(
    (primaryProduct) => {
      processPreselectedProductOptions(
        primaryProduct,
        optionsMultipleMap,
        optionsSingleMap,
      );
    },
  );

  optionalProductTypesWithProducts.forEach(
    (optionalProductType: PricingProductType) => {
      optionalProductType?.products?.forEach((secondaryProduct) => {
        const isMultipleChoice =
          optionalProductType.selectionType ===
          PricingProductSelectionTypes.MULTIPLE_CHOICE;
        const relatedSecondaryProducts = getRelatedSecondaryProducts(
          [secondaryProduct],
          [selectedPrimaryProduct.id, ...relatedPrimaryProductsIds],
        );

        if (!!relatedSecondaryProducts.length && secondaryProduct.preselected) {
          if (isMultipleChoice) {
            productMultipleIds.push(secondaryProduct.id);
          } else {
            productSingleIdsMap[optionalProductType.productTypeId] =
              secondaryProduct.id;
          }
        }

        processPreselectedProductOptions(
          secondaryProduct,
          optionsMultipleMap,
          optionsSingleMap,
        );
      });
    },
  );

  return {
    productMultipleIds,
    productSingleIdsMap,
    optionsMultipleMap,
    optionsSingleMap,
  };
}
