import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Parser from 'html-react-parser';
import classNames from 'classnames';
import './index.scss';

class SVGEmbed extends Component {
  static processSVG(svgContent) {
    if (svgContent) {
      const additionalAttrs = 'preserveAspectRatio="xMidYMid slice"';
      return svgContent.replace(/>/, ` ${additionalAttrs}>`);
    }

    return null;
  }

  componentDidMount() {
    const {
      href,
      svgs: { data: svgs = {} },
    } = this.props;

    if (href && !svgs[href]) {
      this.props.dispatch({
        type: 'ADD_SVG',
        data: {
          [href]: '<svg></svg>',
        },
      });

      fetch(href, {
        method: 'GET',
        headers: {
          'Content-Type': 'image/svg+xml',
        },
      })
        .then((response) => response.text())
        .then((data) => {
          this.props.dispatch({
            type: 'ADD_SVG',
            data: {
              [href]: SVGEmbed.processSVG(data),
            },
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  render() {
    const {
      href,
      transparent = true,
      svgs: { data: svgs = {} } = {},
    } = this.props;

    const componentClassNames = classNames('c-svg-embed', {
      'c-svg-embed--transparent': transparent,
    });

    return !href || !svgs[href] ? null : (
      <div className={componentClassNames}>{Parser(svgs[href])}</div>
    );
  }
}

SVGEmbed.propTypes = {
  href: PropTypes.string,
  transparent: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    svgs: state.svgs,
  };
}

export default connect(mapStateToProps)(SVGEmbed);
