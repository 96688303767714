import TagManager from 'react-gtm-module';
import qs from 'qs';
import uniqBy from 'lodash/uniqBy';
import clientConfig from '../clientConfig';
import parsePurchasedProductsForGTM from './parsePurchasedProductsForGTM';
import { isEnv, isNode, siteTypesEnum } from '../helpers';

const { EVENT, COURSE, BRAND_HUB, AUDIENCE_HUB, ARTICLE, L1_TOPIC_HUB } =
  siteTypesEnum();

const initGTM = {
  gtm: TagManager,

  getAppState() {
    return window.__REDUX_STORE__;
  },

  push(key, value) {
    const dataLayer = { dataLayer: {} };
    if (Array.isArray(key)) {
      key.forEach((name, i) => {
        dataLayer.dataLayer[name] = value[i];
      });
      this.gtm.dataLayer(dataLayer);
    } else {
      dataLayer.dataLayer[key] = value;
      this.gtm.dataLayer(dataLayer);
    }
  },

  pushABTest(name) {
    this.push(['testGroup'], name);
  },

  pushSegment(name = '') {
    this.push('segments', `[${unescape(name).replace(/\|/g, ', ')}]`);
  },

  updateSegmentsLayer(segmentName) {
    const gtmContainerId = this.googleTagManagerContainers?.gtmId;
    if (gtmContainerId && segmentName) {
      const segmentsDataLayer =
        window.google_tag_manager[gtmContainerId].dataLayer.get('segments');
      let segmentsArray = segmentsDataLayer.replace(/[[\]\s]/g, '') || [];
      if (segmentsArray.length) {
        segmentsArray = segmentsArray.split(',');
      }
      segmentsArray.push(segmentName);
      const segmentsListString = `[${segmentsArray}]`;
      window.google_tag_manager[gtmContainerId].dataLayer.set(
        'segments',
        segmentsListString,
      );
      window.dataLayer.find((layer) => layer.segments).segments =
        segmentsListString;
    }
  },

  pushNavBar(gaLabel) {
    this.push(
      ['event', 'ga'],
      [
        'ga-event',
        {
          event: {
            action: 'click',
            category: 'Navbar',
            label: gaLabel,
          },
        },
      ],
    );
  },

  pushFormType(formType) {
    this.push(
      'formType',
      formType === 'REGISTRATION' ? 'Sign-up form' : 'Update form',
    );
  },

  pushBuy(counter) {
    this.push(
      ['event', 'ga'],
      [
        'ga-event',
        {
          event: {
            action: 'click',
            category: 'Buy',
            label: 'Buy',
            value: counter,
          },
        },
      ],
    );
  },

  pushFilter(category, label, value) {
    this.push(
      ['event', 'ga'],
      [
        'ga-event',
        {
          event: {
            action: 'change',
            category,
            label,
            value,
          },
        },
      ],
    );
  },

  pushCheckoutDetails(products, step) {
    this.push(
      ['event', 'ga'],
      [
        'ga-event',
        {
          event: 'Checkout',
          ecommerce: {
            checkout: {
              actionField: { step },
              products,
            },
          },
        },
      ],
    );
  },

  pushProductDetails(packages) {
    this.push(
      ['event', 'ga'],
      [
        'ga-event',
        {
          event: 'Product Details',
          ecommerce: {
            detail: {
              products: packages,
            },
          },
        },
      ],
    );
  },

  pushSession(label) {
    this.push(
      ['event', 'ga'],
      [
        'ga-event',
        {
          event: {
            action: 'click',
            category: 'Session',
            label,
          },
        },
      ],
    );
  },

  pushReceipt(orderReceipt) {
    const getVariants = (packageSelection = []) => {
      let variant = '';

      packageSelection.forEach((p) => {
        variant += p.productName;
      });

      return variant;
    };

    const getCategory = (packageSelection = []) => {
      let category = '';
      const toString = (arr = []) => {
        let s = '';
        arr.forEach((a) => {
          s += a;
        });
        return s;
      };

      packageSelection.forEach((p) => {
        category += toString(p.optionNames);
      });

      return category;
    };

    const products = (name = '', tickets = []) => {
      const products = [];

      tickets.forEach((ticket) => {
        products.push({
          name,
          quantity: ticket.delegates.length,
          variant: getVariants(ticket.packageSelection),
          category: getCategory(ticket.packageSelection),
          brand: name,
        });
      });
      return products;
    };

    const {
      pageConfig: { portfolioId, tenantId } = {},
      siteHeader: { data: { title } = {} } = {},
    } = this.getAppState();

    this.push(
      ['ecommerce'],
      [
        {
          currencyCode: orderReceipt.currency,
          purchase: {
            actionField: {
              action: 'purchase',
              id: orderReceipt.uuid,
              affiliation: (portfolioId || tenantId || '').toLowerCase(),
              coupon: orderReceipt.promotionCode,
              revenue: orderReceipt.totalPriceIncVat,
              tax: orderReceipt.totalPriceVat,
            },
            products: products(title, orderReceipt.ticketPackages),
          },
        },
      ],
    );

    if (orderReceipt) {
      const receiptPushed = sessionStorage.getItem(orderReceipt.uuid);

      if (receiptPushed) {
        return;
      }

      const products = parsePurchasedProductsForGTM(orderReceipt);

      this.push(
        ['Purchase'],
        [
          {
            event: 'Purchase',
            ecommerce: {
              purchase: {
                actionField: {
                  id: orderReceipt.uuid,
                  revenue: orderReceipt.totalPriceIncVat,
                  tax: orderReceipt.totalPriceVat,
                  coupon: orderReceipt.promotionCode,
                },
                products,
              },
            },
          },
        ],
      );
    }
    sessionStorage.setItem(orderReceipt.uuid, 'true');
  },

  pushContentType(contentType) {
    this.push('contentType', contentType);
  },

  pushArticlePortfolio(portfolio) {
    this.push('mainPortfolio', portfolio);
  },

  pushDivisionOption(division) {
    const { pageConfig: { siteType } = {} } = this.getAppState();

    if (!division || siteType !== COURSE) {
      return;
    }

    const { divisionId, divisionName, divisionInfrontBusinessUnit } = division;

    if (divisionId) {
      this.push('divisionOption.id', divisionId);
    }

    if (divisionName) {
      this.push('divisionOption.name', divisionName);
    }

    if (divisionInfrontBusinessUnit) {
      this.push(
        'divisionOption.infrontBusinessUnit',
        divisionInfrontBusinessUnit,
      );
    }
  },

  pushHubOptionsData(data = {}) {
    const { subBrandName, l1Topics, l2Topic, linkedSubBrandNames } = data;

    if (subBrandName) {
      this.push('brand', [subBrandName]);
    }

    if (l1Topics) {
      this.push('l1Topics', l1Topics);
    }

    if (l2Topic) {
      this.push('l2Topics', [l2Topic]);
    }

    if (linkedSubBrandNames) {
      this.push('linkedBrands', linkedSubBrandNames);
    }
  },

  // https://itmebusiness.atlassian.net/browse/KT-1266
  // https://itmebusiness.atlassian.net/browse/FC-5693
  pushHubProductCodeData(data = {}) {
    const {
      id,
      name,
      domain,
      path,
      type,
      brandUrlSuffix,
      digitalSponsorshipCode,
      newsletterCode,
      productCode,
    } = data;

    this.push('knnhub', {
      id,
      name,
      domain,
      subDomain: brandUrlSuffix,
      path,
      type,
      products: {
        promotionalEmailsCode: productCode,
        newsletterCode,
        digitalSponsorshipCode,
      },
    });
  },

  pushHubArticleData(data = {}) {
    const {
      author: { forename = '', surname = '' } = {},
      communityPath,
      articleId,
      datePublished,
      title,
      relatedTopics,
      l1Topics,
      l2Topics,
    } = data;

    if (articleId) {
      this.push('knnarticle', {
        authorName: `${forename} ${surname}`,
        communityPath,
        id: articleId,
        publicationDate: datePublished,
        title,
        relatedTopics,
      });
    }

    if (l1Topics) {
      this.push('l1Topics', l1Topics);
    }

    if (l2Topics) {
      this.push('l2Topics', l2Topics);
    }
  },

  setEventScope() {
    const {
      siteType: { data: { type = '' } = {} },
      siteHeader: {
        data: {
          title,
          eventId = '',
          eventCode = '',
          daysToEventEnd = '',
          l1Topics = '',
          l2Topics = '',
          courseId = '',
          courseCode = '',
          daysToCourseEnd = '',
        } = {},
      } = {},
      options: {
        data: {
          division: {
            id: divisionId,
            name: divisionName,
            infrontBusinessUnitId,
          } = {},
        } = {},
      } = {},
    } = this.getAppState();

    if (title) {
      const isEvent = type === EVENT;
      const knnEvent = {
        'knnevent.name': title,
        'knnevent.eventId': isEvent ? eventId : courseId,
        'knnevent.eventCode': isEvent ? eventCode : courseCode,
        get 'knnevent.daysToEventEnd'() {
          const knnEventDaysToEnd = isEvent ? daysToEventEnd : daysToCourseEnd;
          return knnEventDaysToEnd === 'null' ? '0' : knnEventDaysToEnd;
        },
      };
      Object.keys(knnEvent).forEach((key) => {
        this.push(key, knnEvent[key]);
      });
    }

    if (l1Topics) {
      this.push('l1Topics', l1Topics);
    }

    if (l2Topics) {
      this.push('l2Topics', l2Topics);
    }

    if (divisionId) {
      this.push('division.id', divisionId);
    }

    if (divisionName) {
      this.push('division.name', divisionName);
    }

    if (infrontBusinessUnitId) {
      this.push('division.infrontBusinessUnit', infrontBusinessUnitId);
    }
  },

  isContentGated(value) {
    this.push('gatedContent', value);
  },

  isUserLoggedIn(value) {
    this.push('userLoggedIn', value);
  },

  pushUserID(value) {
    this.push('userID', value);
  },

  setIndustryHomeScope() {
    const { industryHeader: { data: { l1Topics } = {} } = {} } =
      this.getAppState();

    if (l1Topics) {
      this.push('l1Topics', l1Topics);
    }
  },

  setAudienceHomeScope() {
    const { audienceHeader: { data: { l1Topics, l2Topics } = {} } = {} } =
      this.getAppState();

    if (l2Topics) {
      this.push('l1Topics', l1Topics);
      this.push('l2Topics', l2Topics);
    }
  },

  setArticleScope() {
    const {
      articleHome: {
        data: {
          author: { forename = '', surname = '' } = {},
          communityPath,
          articleId,
          datePublished,
          title,
          relatedTopics,
        } = {},
      } = {},
    } = this.getAppState();

    if (articleId) {
      this.push('knnarticle', {
        authorName: `${forename} ${surname}`,
        communityPath,
        id: articleId,
        publicationDate: datePublished,
        title,
        relatedTopics,
      });
    }
  },

  setNewArticleScope() {
    const {
      data: {
        author: { forename = '', surname = '' } = {},
        communityPath,
        articleId,
        datePublished,
        title,
        relatedTopics,
      } = {},
    } = this.getAppState().article.home.data;

    if (articleId) {
      this.push('knnarticle', {
        id: articleId,
        title,
        communityPath,
        publicationDate: datePublished,
        authorName: `${forename} ${surname}`,
        relatedTopics,
      });
    }
  },

  setSegmentsScope() {
    const { options: { data: { maConfig = {} } = {} } = {} } =
      this.getAppState();
    const segment =
      maConfig['x-ma-segments'] ||
      (
        document.cookie.split(';').find((e) => e.includes('TUMGM')) || '='
      ).split('=')[1];

    this.pushSegment([segment]);
  },

  setSubBrands() {
    const {
      options: {
        loaded,
        data: { subBrands = [] },
      },
    } = this.getAppState();

    if (loaded) {
      this.push('brand', subBrands);
    }
  },

  setDataLayer() {
    const { pageConfig: { sitePath, portfolioId, tenantId } = {} } =
      this.getAppState();

    this.push('knnevent', {
      domain: (portfolioId || tenantId || '').toLowerCase(),
      path: sitePath.length > 1 ? sitePath : '',
    });

    this.setEventScope();
    this.setIndustryHomeScope();
    this.setAudienceHomeScope();
    this.setArticleScope();
    this.setNewArticleScope();
    this.setSegmentsScope();
    this.setSubBrands();
  },

  pushGoogleAnalyticsId(id) {
    this.push('knnevent.analytics', id);
  },

  getCustomGoogleTagManagerIdsBySiteType(siteType) {
    const state = this.getAppState();

    switch (siteType) {
      case BRAND_HUB:
        return state.brandHub.options.data.googleTagManagerIds;

      case ARTICLE:
      case AUDIENCE_HUB:
        return state.audienceHUB.options.data.googleTagManagerIds;

      case L1_TOPIC_HUB:
        return state.brandHub.industryHeader.data.googleTagManagerIds;

      case EVENT:
      case COURSE:
        return state.siteHeader.data.googleTagManagerIds;

      default:
        return [];
    }
  },

  addGoogleTagManagerContainers(ids = []) {
    const newContainers = ids.map((gtmId) => {
      return { gtmId, dataLayer: this.googleTagManagerDataLayerConfig };
    });

    this.googleTagManagerContainers = [
      ...this.googleTagManagerContainers,
      ...newContainers,
    ];
  },

  setupGoogleTagManager() {
    const {
      siteType: { data: { type: siteType } = {} },
      options: {
        data: { division: { id: divisionId } = {} },
      },
      pageConfig: { tenantId },
    } = this.getAppState();

    this.googleTagManagerConfig = clientConfig.googleTagManagerConfig;
    this.googleTagManagerDataLayerConfig =
      this.googleTagManagerConfig.dataLayer;

    const ids =
      this.googleTagManagerConfig.tenants[tenantId] ??
      this.googleTagManagerConfig.tenants.DEFAULT;

    this.googleTagManagerContainers = ids.map((gtmId) => ({
      gtmId,
      dataLayer: this.googleTagManagerDataLayerConfig,
    }));

    this.addGoogleTagManagerContainers(
      this.googleTagManagerConfig.divisions[divisionId],
    );

    this.addGoogleTagManagerContainers(
      this.getCustomGoogleTagManagerIdsBySiteType(siteType),
    );

    this.googleTagManagerContainers = uniqBy(
      this.googleTagManagerContainers,
      (o) => o.gtmId,
    );

    this.googleTagManagerContainers.forEach((container) => {
      this.gtm.initialize(container);
    });
  },

  init() {
    if (isNode() || isEnv('local')) return;

    const { externalScripts } = qs.parse(document.location.search, {
      ignoreQueryPrefix: true,
    });
    if (externalScripts === 'disabled') return;

    this.counter = this.counter ?? 0;

    this.setDataLayer();
    this.setupGoogleTagManager();
  },
};

export default initGTM;
