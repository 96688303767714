import { createSelector, PayloadAction } from '@reduxjs/toolkit';
import {
  createGenericAsyncThunk,
  createGenericBuilderCases,
  createGenericSlice,
} from '../generic';
import {
  GenericState,
  FacetType,
  UniqueIdentifier,
  UploadedImage,
} from '../../../types';
import { DiscoveryContentTypes, DeliveryType } from '../../../constants';
import { RootState } from '../../store';
import {
  getDiscoveryContentByType,
  deleteValueFromFilters,
} from '../../../components/hub/shared/helpers';
import { L2Topic } from '../../../components/hub/brandHub/store/features/industryHome';
import {
  SpeakerSectionContentTypes,
  SpeakerSectionDisplayTypes,
} from '../../../components/hub/shared/siteBuilder/modules/SpeakersModule/types';

export interface DiscoverySponsor {
  photo: string;
  path: string;
  name: string;
}

export interface DiscoveryPartner {
  id: string;
  logo?: UploadedImage;
  title: string;
  type: string[];
}

export interface DiscoveryTopic {
  name: string;
  brand: string;
}

export interface DiscoveryLocation {
  name: string;
  city: string;
  address: string;
  country: string;
  searchCity: string;
}

export interface DiscoveryDate {
  start?: string;
  end?: string;
  formatted?: string;
}

export enum DiscoveryFeaturedTypes {
  LARGE = 'LARGE',
  REGULAR = 'REGULAR',
}

export interface DiscoveryContent {
  id: UniqueIdentifier;
  featuredType: DiscoveryFeaturedTypes;
  topics: DiscoveryTopic[];
}

export interface DiscoveryArticle extends DiscoveryContent {
  title: string;
  imageUrl: string;
  content: string;
  path: string;
  summary: string;
  datePublished: string;
  dateModified: string;
  externalLinkEnabled: boolean;
  externalLink: string;
}

export enum DiscoveryStreamlyVideoAccessType {
  REGISTER = 'Register',
  SUBSCRIBE = 'Subscribe',
  PUBLIC = 'Public',
}

export interface DiscoveryStreamlyVideo extends DiscoveryContent {
  title: string;
  description: string;
  categoryName: string;
  content: string;
  duration: number;
  featured: boolean;
  thumbnailImageUrl: string;
  url: string;
  accessType: DiscoveryStreamlyVideoAccessType;
}

export interface DiscoverySite extends DiscoveryContent {
  name: string;
  path: string;
  sectors: string[];
  subBrands: string[];
  deliveryTypes: string[];
  sponsors: DiscoverySponsor[];
  partners: DiscoveryPartner[];
  sponsorLogo1Path: string;
  sponsorLogo2Path: string;
  logo: string;
  imageBackground: string;
  location: DiscoveryLocation;
  locations: DiscoveryLocation[];
  date: DiscoveryDate;
  dates: DiscoveryDate[];
  timeAsText: string;
  vertical: string;
  type: DiscoveryContentTypes.EVENT | DiscoveryContentTypes.COURSE;
  secondaryColor: string;
  description: string;
  contentId: UniqueIdentifier;
  featured: boolean;
  tenant: string;
  languages: string[];
}

export interface DiscoveryTopicHome extends L2Topic, DiscoveryContent {}

export interface DiscoveryModule {
  id: UniqueIdentifier;
  type: DiscoveryContentTypes.MODULE;
  contentId: string;
  content: string;
  path: string;
  pageName: string;
  moduleTitle: string;
}

export interface DiscoverySpeaker {
  id: UniqueIdentifier;
  type: DiscoveryContentTypes.SPEAKER;
  company: string;
  path: string;
  jobTitle: string;
  content: string;
  title: string;
  contentId: string;
  photo: string;
  forename: string;
  displayHonorific: boolean;
  honorific: string;
  surname: string;
}

export interface DiscoverySiteSponsor {
  id: UniqueIdentifier;
  type: DiscoveryContentTypes.SPONSOR;
  name: string;
  path: string;
  categoryName: string;
  contentId: string;
  content: string;
  photo: string;
}

export interface DiscoveryBrandSpeaker {
  id: UniqueIdentifier;
  type: DiscoveryContentTypes.SPEAKER;
  title: string;
  path: string;
  photo: string;
  company: string;
  jobTitle: string;
}

export interface DiscoveryFilter {
  type: string;
  count: number;
  label: string;
  filterValue: {
    type: string;
    values: string[];
  };
  selected: boolean;
}

export interface DiscoveryRecord<T> {
  type: Exclude<DiscoveryContentTypes, DiscoveryContentTypes.ALL>;
  label: string;
  results: T[];
  count: number;
  currentPage: number;
  totalPages: number;
}

export interface Discovery {
  facets: {
    [key in FacetType]: DiscoveryFilter[];
  };
  records: [
    | DiscoveryRecord<DiscoverySite>
    | DiscoveryRecord<DiscoveryArticle>
    | DiscoveryRecord<DiscoveryStreamlyVideo>
    | DiscoveryRecord<DiscoveryModule>
    | DiscoveryRecord<DiscoverySpeaker>
    | DiscoveryRecord<DiscoverySiteSponsor>
    | DiscoveryRecord<DiscoveryTopicHome>,
  ];
}

export enum SortTypes {
  FEATURED = 'FEATURED',
  DATE = 'DATE',
  ALPHABETICAL = 'ALPHABETICAL',
}

export type FacetsConfig = {
  attendanceEnabled: boolean;
  courseTypesEnabled: boolean;
  datesEnabled: boolean;
  locationEnabled: boolean;
  sectorsEnabled: boolean;
  subBrandsEnabled: boolean;
  topicsEnabled: boolean;
};

export type SearchContentTypeConfig = {
  allEnabled: boolean;
  topicHomesEnabled: boolean;
  eventsEnabled: boolean;
  coursesEnabled: boolean;
  articlesEnabled: boolean;
  videosEnabled: boolean;
  speakersEnabled: boolean;
  agendaSessionsEnabled: boolean;
};

export type SiteSearchContentTypeConfig = {
  allEnabled: boolean;
  modulesEnabled: boolean;
  speakersEnabled: boolean;
  sponsorsEnabled: boolean;
};

export type FacetsConfigData = {
  facetsConfig: FacetsConfig;
  subBrands: string[];
  contentTypeConfig: SearchContentTypeConfig;
};

export type SiteFacetsConfigData = {
  contentTypeConfig: SiteSearchContentTypeConfig;
};

export interface DiscoveryValues {
  type: DiscoveryContentTypes[];
  page: number;
  count: number;
  cityCountry: string[];
  courseType: string[];
  datePublishedMillis: string[];
  deliveryType: DeliveryType[];
  availableDeliveryType: DeliveryType[];
  fallbackLocale: string;
  largeFeaturedContentIds: UniqueIdentifier[];
  featuredContentIds: UniqueIdentifier[];
  fetchIds: UniqueIdentifier[];
  location: string[];
  availableLocation: string[];
  month: string[];
  requestLocale: string;
  searchInput: string;
  sectors: string[];
  availableSectors: string[];
  subBrands: string[];
  availableSubBrands: string[];
  mediaSite: string[];
  startDateMillis: string[];
  topics: string[];
  availableTopics: string[];
  language: string[];
  availableLanguage: string[];
  awardGroup: string[];
  availableAwardGroup: string[];
  informationType: string[];
  availableInformationType: string[];
  partners: string[];
  availablePartners: string[];
  creditPartners: string[];
  availableCreditPartners: string[];
  mainFilterTopic: string;
  facetsConfig: FacetsConfig;
  requestedSortType: SortTypes;
}

export type HubDiscoveryValues = Partial<DiscoveryValues>;

export type SiteDiscoveryValues = Partial<{
  type: DiscoveryContentTypes[];
  page: number;
  count: number;
  searchInput: string;
  pageNames: string[];
  streamNames: string[];
  categoryNames: string[];
  facetsConfig: FacetsConfig;
}>;

export interface FetchHubModuleDiscoveryParams {
  values: HubDiscoveryValues;
  moduleName: 'articles' | 'events' | 'courses' | 'streamly-videos';
}

export const fetchHubModuleDiscovery = createGenericAsyncThunk<
  Discovery,
  FetchHubModuleDiscoveryParams
>(
  'hubDiscovery/fetchHubModuleDiscovery',
  async ({ values, moduleName }, { extra, rejectWithValue }) => {
    const response = await extra.ajax.post(
      `/caas/discovery/api/v1/discovery/hub-search/modules/${moduleName}`,
      deleteValueFromFilters(values, DiscoveryContentTypes.ALL),
      {
        headers: {
          'Content-Type': 'application/json',
          'Published-State': extra.headers['Published-State'],
        },
      },
    );
    const data: Discovery = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  },
);

export interface SponsorSection {
  displayType: string;
  eventId: UniqueIdentifier;
  sponsorCategoryIds: UniqueIdentifier[];
}

export interface HubSponsorsDiscoveryParams {
  spexs: SponsorSection[];
}

export interface DiscoveryBrandSponsorOrganisation {
  id: UniqueIdentifier;
  contentId: UniqueIdentifier;
  path: string;
  name: string;
  profile: string;
  categoryName: string;
  photo: string;
}

export interface HubSponsorsDiscoveryBlock {
  displayType: string;
  domain: string;
  tenant: string;
  siteName: string;
  sitePath: string;
  spexs: DiscoveryBrandSponsorOrganisation[];
}

export interface HubSponsorsDiscoveryResponse {
  spexBlocks: HubSponsorsDiscoveryBlock[];
}

export const fetchHubSponsorsDiscovery = createGenericAsyncThunk<
  HubSponsorsDiscoveryResponse,
  HubSponsorsDiscoveryParams
>(
  'hubDiscovery/fetchHubSponsorsDiscovery',
  async ({ spexs }, { extra, rejectWithValue }) => {
    const response = await extra.ajax.post(
      `/caas/discovery/api/v1/discovery/hub-search/modules/spex`,
      {
        spexs,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    const data: HubSponsorsDiscoveryResponse = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  },
);

export enum HubPageNames {
  events = 'events',
  courses = 'courses',
  articles = 'articles',
  search = 'search',
  streamlyVideos = 'streamly-videos',
}

export interface FetchHubPageDiscoveryParams {
  values: HubDiscoveryValues;
  pageName: HubPageNames;
  searchQueryData?: FacetsConfigData;
}

export const fetchHubPageDiscovery = createGenericAsyncThunk<
  Discovery,
  FetchHubPageDiscoveryParams
>(
  'hubDiscovery/fetchHubPageDiscovery',
  async ({ values, searchQueryData, pageName }, { extra, rejectWithValue }) => {
    const transformedValues = deleteValueFromFilters(values, 'all');

    const response = await extra.ajax.post(
      `/caas/discovery/api/v1/discovery/hub-search/pages/${pageName}`,
      { ...searchQueryData, ...transformedValues },
      {
        headers: {
          'Content-Type': 'application/json',
          'Published-State': extra.headers['Published-State'],
        },
      },
    );
    const data: Discovery = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  },
);

export const fetchHubPageDiscoveryData = createGenericAsyncThunk<
  Discovery,
  FetchHubPageDiscoveryParams
>(
  'hubDiscovery/fetchHubPageDiscoveryData',
  async ({ values, pageName }, { extra, rejectWithValue }) => {
    const response = await extra.ajax.post(
      `/caas/discovery/api/v1/discovery/hub-search/pages/${pageName}`,
      deleteValueFromFilters(values, DiscoveryContentTypes.ALL),
      {
        headers: {
          'Content-Type': 'application/json',
          'Published-State': extra.headers['Published-State'],
        },
      },
    );
    const data: Discovery = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  },
);

export interface FetchSitePageDiscoveryParams {
  values: SiteDiscoveryValues;
  siteId: UniqueIdentifier;
  siteTypePath: string;
  searchQueryData?: FacetsConfigData;
}

export const fetchSitePageDiscovery = createGenericAsyncThunk<
  Discovery,
  FetchSitePageDiscoveryParams
>(
  'hubDiscovery/fetchSitePageDiscovery',
  async (
    { values, siteId, siteTypePath, searchQueryData },
    { extra, rejectWithValue },
  ) => {
    const transformedValues = deleteValueFromFilters(values, 'all', true);
    const response = await extra.ajax.post(
      `/caas/discovery/api/v1/discovery/${siteTypePath}/${siteId}/search`,
      { ...searchQueryData, ...transformedValues },
      {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': extra.i18n.language,
          'Published-State': extra.headers['Published-State'],
        },
      },
    );
    const data: Discovery = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  },
);

export const fetchSitePageDiscoveryData = createGenericAsyncThunk<
  Discovery,
  FetchSitePageDiscoveryParams
>(
  'hubDiscovery/fetchHubPageDiscoveryData',
  async ({ values, siteId, siteTypePath }, { extra, rejectWithValue }) => {
    const response = await extra.ajax.post(
      `/caas/discovery/api/v1/discovery/${siteTypePath}/${siteId}/search`,
      deleteValueFromFilters(values, DiscoveryContentTypes.ALL, true),
      {
        headers: {
          'Content-Type': 'application/json',
          'Published-State': extra.headers['Published-State'],
        },
      },
    );
    const data: Discovery = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  },
);

export interface SpeakerSection {
  contentType: SpeakerSectionContentTypes;
  displayType: SpeakerSectionDisplayTypes;
  siteId: UniqueIdentifier;
  speakerIds: UniqueIdentifier[];
  speakerTypes: string[];
}

export interface HubSpeakersDiscoveryParams {
  speakers: SpeakerSection[];
}

export interface HubSpeakersDiscoveryBlock {
  domain: string;
  tenant: string;
  siteName: string;
  sitePath: string;
  contentType: string;
  speakers: DiscoveryBrandSpeaker[];
}

export interface HubSpeakersDiscoveryResponse {
  speakerBlocks: HubSpeakersDiscoveryBlock[];
}

export const fetchHubSpeakersDiscovery = createGenericAsyncThunk<
  HubSpeakersDiscoveryResponse,
  HubSpeakersDiscoveryParams
>(
  'hubDiscovery/fetchHubSpeakersDiscovery',
  async (values, { extra, rejectWithValue }) => {
    const response = await extra.ajax.post(
      `/caas/discovery/api/v1/discovery/hub-search/modules/speakers`,
      {
        speakers: values.speakers,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    const data: HubSpeakersDiscoveryResponse = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  },
);

function saveRecords<
  T extends
    | DiscoveryArticle
    | DiscoverySite
    | DiscoveryTopicHome
    | DiscoveryModule
    | DiscoverySpeaker
    | DiscoverySiteSponsor
    | DiscoveryStreamlyVideo,
>(
  state: GenericState<Discovery>,
  contentType: DiscoveryContentTypes,
  records: Discovery['records'] | [] = [],
) {
  const { results: loadedResults = [], currentPage } =
    getDiscoveryContentByType<T>(contentType, records);

  const currentRecord = state.data.records.find(
    (record) => record.type === contentType,
  ) as DiscoveryRecord<T>;

  if (loadedResults.length > 0 && currentRecord) {
    currentRecord.results = currentRecord.results.concat(loadedResults);
    currentRecord.currentPage = currentPage;
  }
}

const hubDiscoverySlice = createGenericSlice({
  name: 'hubDiscoverySlice',
  initialState: {
    status: 'idle',
    data: {
      records: [],
      facets: {},
    },
  } as unknown as GenericState<Discovery>,
  reducers: {
    updatePreloadedProductsList(
      state,
      payloadAction: PayloadAction<{
        contentType: DiscoveryContentTypes;
        data: Discovery;
      }>,
    ) {
      const {
        payload: { contentType, data },
      } = payloadAction;

      switch (contentType) {
        case DiscoveryContentTypes.EVENT:
        case DiscoveryContentTypes.COURSE: {
          saveRecords<DiscoverySite>(state, contentType, data.records);
          break;
        }

        case DiscoveryContentTypes.ARTICLE: {
          saveRecords<DiscoveryArticle>(state, contentType, data.records);
          break;
        }

        case DiscoveryContentTypes.STREAMLY_VIDEO: {
          saveRecords<DiscoveryStreamlyVideo>(state, contentType, data.records);
          break;
        }

        case DiscoveryContentTypes.AUDIENCE_HOME: {
          saveRecords<DiscoveryTopicHome>(state, contentType, data.records);
          break;
        }

        case DiscoveryContentTypes.MODULE: {
          saveRecords<DiscoveryModule>(state, contentType, data.records);
          break;
        }

        case DiscoveryContentTypes.SPEAKER: {
          saveRecords<DiscoverySpeaker>(state, contentType, data.records);
          break;
        }

        case DiscoveryContentTypes.SPONSOR: {
          saveRecords<DiscoverySiteSponsor>(state, contentType, data.records);
          break;
        }

        default:
          break;
      }
    },
  },
  extraReducers: (builder) => {
    createGenericBuilderCases<Discovery, FetchHubPageDiscoveryParams>(
      builder,
      fetchHubPageDiscovery,
    );

    createGenericBuilderCases<Discovery, FetchSitePageDiscoveryParams>(
      builder,
      fetchSitePageDiscovery,
    );
  },
});

export const defaultRecord = {
  results: [],
  count: 0,
  totalPages: 0,
};

export const selectHubDiscovery = createSelector(
  [(state: RootState) => state.discovery],
  (data) => data,
);

export const selectHubDiscoveryFacets = createSelector(
  [(state: RootState) => state.discovery.data.facets],
  (facets) => facets,
);

export const selectHubDiscoverySiteRecord = createSelector(
  [
    (state: RootState) => state.discovery.data.records,
    (state: RootState, type: DiscoveryContentTypes) => type,
  ],
  (records, type) => getDiscoveryContentByType<DiscoverySite>(type, records),
);

export const selectHubDiscoveryArticleRecord = createSelector(
  [(state: RootState) => state.discovery.data.records],
  (records) =>
    getDiscoveryContentByType<DiscoveryArticle>(
      DiscoveryContentTypes.ARTICLE,
      records,
    ),
);

export const selectHubDiscoveryTopicHomesRecord = createSelector(
  [(state: RootState) => state.discovery.data.records],
  (records) =>
    getDiscoveryContentByType<DiscoveryTopicHome>(
      DiscoveryContentTypes.AUDIENCE_HOME,
      records,
    ),
);

export const selectHubDiscoveryStreamlyVideosRecord = createSelector(
  [(state: RootState) => state.discovery.data.records],
  (records) =>
    getDiscoveryContentByType<DiscoveryStreamlyVideo>(
      DiscoveryContentTypes.STREAMLY_VIDEO,
      records,
    ),
);

export const selectSiteDiscoveryModuleRecord = createSelector(
  [(state: RootState) => state.discovery.data.records],
  (records) =>
    getDiscoveryContentByType<DiscoveryModule>(
      DiscoveryContentTypes.MODULE,
      records,
    ),
);

export const selectSiteDiscoverySpeakerRecord = createSelector(
  [(state: RootState) => state.discovery.data.records],
  (records) =>
    getDiscoveryContentByType<DiscoverySpeaker>(
      DiscoveryContentTypes.SPEAKER,
      records,
    ),
);

export const selectSiteDiscoverySponsorRecord = createSelector(
  [(state: RootState) => state.discovery.data.records],
  (records) =>
    getDiscoveryContentByType<DiscoverySiteSponsor>(
      DiscoveryContentTypes.SPONSOR,
      records,
    ),
);

export const { start, fulfilled, rejected, updatePreloadedProductsList } =
  hubDiscoverySlice.actions;

export default hubDiscoverySlice.reducer;
