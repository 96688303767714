import { combineReducers } from '@reduxjs/toolkit';

import sitePricingProductsReducer from './features/products/index';
import sitePricingPackagesReducer from './features/packages/index';
import sitePricingContentReducer from './features/content/index';
import sitePricingCheckoutDetailsReducer from './features/checkoutDetails/index';
import coursePricingOptionsReducer from './features/courseOptions/index';
import sitePricingBookingClosed from './features/bookingClosed';
import sitePricingVIPCode from './features/discounts';
import sitePricingOrderReceipt from './features/orderReceipt';

const pricingReducer = {
  pricing: combineReducers({
    products: sitePricingProductsReducer,
    packages: sitePricingPackagesReducer,
    content: sitePricingContentReducer,
    courseOptions: coursePricingOptionsReducer,
    checkoutDetails: sitePricingCheckoutDetailsReducer,
    bookingClosed: sitePricingBookingClosed,
    vipCode: sitePricingVIPCode,
    orderReceipt: sitePricingOrderReceipt,
  }),
};

export default pricingReducer;
