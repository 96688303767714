import React from 'react';
import cn from 'classnames';
import { Anchor, AnchorProps } from '../../../../../../shared/Anchor';
import styles from './CardAnchor.module.scss';

export interface CardAnchorProps extends AnchorProps {}

export function CardAnchor(props: CardAnchorProps) {
  const { className, children, ...restProps } = props;

  return (
    <Anchor {...restProps} className={cn(styles.anchor, className)}>
      {children}
    </Anchor>
  );
}
