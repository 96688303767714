import React, { useMemo } from 'react';
import { AnchorProps } from '../../../../../shared/Anchor';
import { DiscoveryArticle } from '../../../../../../store/features/discovery';
import { useHubContext } from '../../../context/hubContext';
import { getDiscoveryTopicsByBrand } from '../../../helpers';
import { createCanonicalUrl } from '../../../../../../helpers';
import { useRootSelector } from '../../../../../../store/hooks';
import { selectPageConfig } from '../../../../../../store/features/pageConfig';
import {
  CardAnchor,
  Image,
  Title,
  Badge,
  TopicsList,
  CardButton,
  TitleProps,
  ImageProps,
  CardButtonProps,
} from '../components';
import {
  ProductCard,
  ProductListCard,
  ProductInlineCard,
} from '../../products';
import { TopicsListType } from '../SiteCard';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import styles from './ArticleCard.module.scss';

export enum ArticleCardTypes {
  default = 'default',
  featured = 'featured',
  list = 'list',
  inline = 'inline',
}

export interface ArticleCardProps extends Omit<AnchorProps, 'to'> {
  data: DiscoveryArticle;
  cardType?: keyof typeof ArticleCardTypes;
  showFilter?: boolean;
}

export const ArticleCard = (props: ArticleCardProps) => {
  const {
    data: {
      path,
      title,
      imageUrl: imagePath,
      topics = [],
      summary,
      datePublished,
      dateModified,
      externalLinkEnabled,
      externalLink,
    },
    cardType,
    showFilter,
    ...restProps
  } = props;
  const { brandName } = useHubContext();
  const { parentDomain } = useRootSelector(selectPageConfig);
  const topicsList = useMemo(
    () => getDiscoveryTopicsByBrand(topics, brandName),
    [topics, brandName],
  );

  const getImage = (style?: ImageProps['imageStyle']) => (
    <Image imageStyle={style} path={imagePath} />
  );
  const getTitle = (
    size?: TitleProps['size'],
    className?: TitleProps['className'],
  ) => (
    <Title size={size} className={className}>
      {title}{' '}
      {externalLinkEnabled && (
        <OpenInNewIcon fontSize="inherit" sx={{ verticalAlign: 'middle' }} />
      )}
    </Title>
  );
  const getTopicsList = ({ count, ...props }: TopicsListType) =>
    topicsList.length > 0 && (
      <TopicsList list={topicsList.slice(0, count)}>
        {(topic) => (
          <Badge theme="outlined" size="tiny" {...props}>
            {topic}
          </Badge>
        )}
      </TopicsList>
    );
  const getShowMoreBadge = (size?: CardButtonProps['size']) => (
    <CardButton size={size}>Read More</CardButton>
  );

  const imageUrl = createCanonicalUrl(imagePath, null, parentDomain).replace(
    /\/$/,
    '',
  );

  return (
    <CardAnchor {...restProps} to={externalLinkEnabled ? externalLink : path}>
      {cardType === ArticleCardTypes.default && (
        <ProductCard
          image={getImage()}
          title={getTitle('extraSmall')}
          topics={getTopicsList({ count: 1 })}
          leftFooter={getShowMoreBadge()}
        />
      )}
      {cardType === ArticleCardTypes.featured && (
        <ProductListCard
          image={getImage('list')}
          imageSize={showFilter ? 'medium' : 'small'}
          title={getTitle('large')}
          leftHeader={getTopicsList({ count: 3 })}
          rightHeader={<Badge size="medium">Featured</Badge>}
          showMoreBadge={getShowMoreBadge()}
        />
      )}
      {cardType === ArticleCardTypes.list && (
        <ProductListCard
          image={getImage('list')}
          title={getTitle()}
          leftHeader={getTopicsList({ count: 3 })}
          showMoreBadge={getShowMoreBadge()}
        />
      )}
      {cardType === ArticleCardTypes.inline && (
        <ProductInlineCard
          leftHeader={getTopicsList({
            theme: 'text',
            className: styles.inlineTopic,
            size: 'small',
            count: 1,
          })}
          title={getTitle('extraSmall', styles.inlineTitle)}
        />
      )}
      <div itemScope itemType="https://schema.org/Article">
        <meta
          itemProp="mainEntityOfPage"
          content={createCanonicalUrl(path, null, parentDomain)}
        />
        <meta itemProp="datePublished" content={datePublished} />
        <meta itemProp="dateModified" content={dateModified} />
        <meta itemProp="image.url" content={imageUrl} />
        <meta itemProp="description" content={summary} />
      </div>
    </CardAnchor>
  );
};

ArticleCard.defaultProps = {
  cardType: ArticleCardTypes.default,
};
