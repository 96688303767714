import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import loadable from '@loadable/component';

import { withBrand } from '../../../../providers/BrandProvider';
import { HTMLtoJSX } from '../../../../helpers';

import SiteUpperFooter from './SiteUpperFooter/SiteUpperFooter';
import SiteLowerFooter from './SiteLowerFooter/SiteLowerFooter';
import Advertising from '../../../shared/Advertising/Advertising';

import {
  DivisionalMarketingFooter,
  MarketingFooter,
  DivisionalFooter,
  CopyrightFooter,
  TenantLogo,
} from '../../../../informakit';

import './SiteFooter.scss';

const FanExpoHQFooter = loadable(() =>
  import(
    /* webpackChunkName: "FanExpoHQFooter" */ '../layoutComponents/FanExpoHQFooter/FanExpoHQFooter'
  ),
);
const FestivalFooter = loadable(() =>
  import(
    /* webpackChunkName: "FestivalFooter" */ '../layoutComponents/FestivalFooter/FestivalFooter'
  ),
);

function getDivisionalMarketingFooterConfig(tenantId) {
  if (tenantId === 'CONNECTECHASIA') {
    return {
      discoverTitle: 'Discover more from Informa Tech',
      menuLeft: [
        {
          title: 'Advisory Committee',
          href: '/home/advisory-committee/',
        },
        {
          title: 'Asia Tech x Singapore',
          href: 'https://asiatechxsg.com/',
        },
        {
          title: 'Contact Us',
          href: '/home/contact/',
        },
      ],
      menuRight: [
        {
          title: 'Sustainability',
          href: '/home/sustainability/',
        },
        {
          title: 'TMT Events',
          href: 'https://tmt.knect365.com/',
        },
        {
          title: 'Tourism',
          href: 'https://get.connectechasia.com/tourism/',
        },
      ],
      secondMenuTitle: '',
      secondaryMenu: [],
      divisionalFooter: [
        {
          title: 'Home',
          href: 'https://tech.informa.com/',
        },
        {
          title: 'Cookies',
          href: 'https://knect365.com/cookie-policy/',
        },
        {
          title: 'CCPA: Do not sell my personal info',
          href: 'https://privacyportal-eu-cdn.onetrust.com/dsarwebform/c1f53e84-9f05-4169-a854-85052b63c50b/5f26b553-52cc-4973-a761-295d5634a6b6.html',
        },
        {
          title: 'Privacy',
          href: 'https://www.informa.com/privacy-policy/',
        },
        {
          title: 'Terms',
          href: 'https://tech.informa.com/terms-and-conditions/',
        },
      ],
      lowerFooter: {
        logo: {
          link: 'https://tech.informa.com/',
          target: '_self',
          image: '/images/informa-tech-logo-footer@2x.png',
          alt: 'Informa Tech logo',
          prefix: 'Brought to you by',
          logoType: 'techlogo',
        },
      },
      copyright: [
        'Copyright © 2024 Informa PLC. Informa Telecoms & Media Limited is a company registered in England and Wales with company number 00991704 whose registered office is 5 Howick Place, London, SW1P 1WG. VAT GB365462636. Informa Telecoms & Media Limited is part of Informa PLC',
      ],
    };
  }

  return {
    discoverTitle: 'Discover more from Informa Tech',
    menuLeft: [
      {
        title: 'Informa Tech Home',
        href: 'https://tech.informa.com/',
      },
      {
        title: 'Event Calendar',
        href: 'https://tech.informa.com/events',
      },
      {
        title: 'TMT Events',
        href: 'https://tmt.knect365.com',
      },
      {
        title: 'Automotive Events',
        href: 'https://automotive.knect365.com/',
      },
    ],
    menuRight: [
      {
        title: 'Our Brands',
        href: 'https://tech.informa.com/brands',
      },
      {
        title: 'London Tech Week',
        href: 'https://londontechweek.com/',
      },
      {
        title: 'Asia Tech x Singapore',
        href: 'https://asiatechxsg.com/',
      },
    ],
    secondMenuTitle: 'Working with us',
    secondaryMenu: [
      {
        title: 'About Us',
        href: 'https://tech.informa.com/about-us/',
      },
      {
        title: 'Contact Us',
        href: '/contact/',
        withSitePath: true,
      },
    ],
    divisionalFooter: [
      {
        title: 'Home',
        href: 'https://tech.informa.com/',
      },
      {
        title: 'Cookies',
        href: 'https://knect365.com/cookie-policy/',
      },
      {
        title: 'CCPA: Do not sell my personal info',
        href: 'https://privacyportal-eu-cdn.onetrust.com/dsarwebform/c1f53e84-9f05-4169-a854-85052b63c50b/5f26b553-52cc-4973-a761-295d5634a6b6.html',
      },
      {
        title: 'Privacy',
        href: 'https://www.informa.com/privacy-policy/',
      },
      {
        title: 'Terms',
        href: 'https://tech.informa.com/terms-and-conditions/',
      },
    ],
    lowerFooter: {
      logo: {
        link: 'https://tech.informa.com/',
        target: '_self',
        image: '/images/informa-tech-logo-footer@2x.png',
        alt: 'Informa Tech logo',
        prefix: 'Brought to you by',
        logoType: 'techlogo',
      },
    },
  };
}

export class SiteFooter extends Component {
  static propTypes = {
    footerUpperHidden: PropTypes.bool,
    siteFooter: PropTypes.object,
    brand: PropTypes.object,
    pageConfig: PropTypes.object,
  };

  checkUpperFooterPresence() {
    const {
      siteFooter: { data: { contact = {}, hashtag, social = [] } = {} } = {},
      footerUpperHidden = false,
    } = this.props;

    return (
      !footerUpperHidden &&
      !(Object.keys(contact).length === 0 && !hashtag && social.length === 0)
    );
  }

  getAdvParentStyle() {
    const {
      pageConfig: { tenantConfig: { footerVariant } = {} } = {},
      brand = {},
    } = this.props;

    switch (footerVariant) {
      case 'FanExpoHQ':
      case 'InteriorDesignShow':
        return { backgroundColor: '#d8d8d8' };
      case 'Festival':
        return { backgroundColor: '#d8d8d8' };
      default:
        return { backgroundColor: brand.secondaryColor };
    }
  }

  getSocialList() {
    const {
      siteFooter: { data: { social = [] } = {} },
    } = this.props;

    return social.map((item) => {
      return {
        type: item.type,
        href: item.url,
        brand: item.type !== 'blog',
      };
    });
  }

  renderFooter() {
    const {
      siteFooter: { data: { hashtag } = {} } = {},
      pageConfig: {
        tenantId,
        tenantConfig: { footerVariant, copyright } = {},
      } = {},
      brand = {},
    } = this.props;

    const footerSection = getDivisionalMarketingFooterConfig(tenantId);

    switch (footerVariant) {
      case 'Division':
        return (
          <DivisionalMarketingFooter>
            <MarketingFooter
              backgroundColor={brand.secondaryColor}
              bottomBorder
              eventName={hashtag ? `#${hashtag}` : 'us'}
              discoverMenu={{
                title: footerSection.discoverTitle,
                menuLeft: footerSection.menuLeft,
                menuRight: footerSection.menuRight,
              }}
              secondaryMenu={{
                title: footerSection.secondMenuTitle,
                menu: footerSection.secondaryMenu,
              }}
              socialMenu={this.getSocialList()}
            />
            <DivisionalFooter
              bottomBorder
              showPrefix
              menuItems={footerSection.divisionalFooter}
              Logo={<TenantLogo logoObj={footerSection.lowerFooter.logo} />}
            />
            <CopyrightFooter>{HTMLtoJSX(copyright)}</CopyrightFooter>
          </DivisionalMarketingFooter>
        );
      case 'FanExpoHQ':
      case 'InteriorDesignShow':
        return (
          <footer className="c-event-footer">
            <FanExpoHQFooter bgColor={brand.secondaryColor} />
            <SiteLowerFooter />
          </footer>
        );
      case 'Festival':
        return (
          <footer className="c-event-footer">
            <FestivalFooter />
          </footer>
        );
      default:
        return (
          <footer className="c-event-footer">
            {this.checkUpperFooterPresence() && (
              <SiteUpperFooter
                data={this.props.siteFooter.data}
                bgColor={brand.secondaryColor}
              />
            )}
            <SiteLowerFooter bgColor={brand.primaryColor} />
          </footer>
        );
    }
  }

  render() {
    return (
      <>
        <div style={this.getAdvParentStyle()}>
          <Advertising
            advSlots={[
              { slotId: '300_1_bottom' },
              { slotId: '300_2_bottom' },
              { slotId: '300_3_bottom' },
            ]}
            advSize="300_250"
          />
        </div>
        {this.renderFooter()}
        <Advertising
          advSlots={[{ slotId: '1_1', target: 'adhesion' }]}
          advSize="1_1"
        />
        <Advertising
          advSlots={[{ slotId: '1_2', target: 'floor' }]}
          advSize="1_1"
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    siteFooter: state.siteFooter,
    pageConfig: state.pageConfig,
  };
}

export default connect(mapStateToProps)(withBrand(SiteFooter));
