import { Fragment, ReactNode } from 'react';
import cn from 'classnames';
import { UniqueIdentifier } from '../../../../../../types';
import styles from './ProductsGrid.module.scss';

export enum ProductsCountPerRow {
  three = 'three',
  four = 'four',
}

export interface ProductsGridProps<T> {
  products: T[];
  featuredProducts: T[];
  inlineProducts?: T[];
  renderProduct: (product: T) => ReactNode;
  renderFeaturedProduct: (product: T) => ReactNode;
  renderInlineProduct?: (product: T) => ReactNode;
  productsCountPerRow: keyof typeof ProductsCountPerRow;
}

export function ProductsGrid<T extends { id: UniqueIdentifier }>({
  products,
  featuredProducts,
  inlineProducts = [],
  renderProduct,
  renderFeaturedProduct,
  renderInlineProduct,
  productsCountPerRow,
}: ProductsGridProps<T>) {
  const productColumnClassNames = cn('col-xs-12', {
    'col-md-4': productsCountPerRow === ProductsCountPerRow.three,
    'col-md-3': productsCountPerRow === ProductsCountPerRow.four,
  });
  const inlineProductsColumnClassNames = cn('col-xs-12', {
    'col-md-8': productsCountPerRow === ProductsCountPerRow.three,
    'col-md-6': productsCountPerRow === ProductsCountPerRow.four,
  });

  return (
    <>
      {featuredProducts.length > 0 && (
        <div className="row">
          {featuredProducts.map((product) => (
            <Fragment key={product.id}>
              <div className="col-xs-12">
                <div className={styles.featuredProductWrapper}>
                  {renderFeaturedProduct(product)}
                </div>
              </div>
              <div className="col-xs-12">
                <div className={styles.gutter} />
              </div>
            </Fragment>
          ))}
        </div>
      )}

      {products.length > 0 && (
        <div className="row">
          {products.map((product) => (
            <div
              key={product.id}
              className={productColumnClassNames}
              data-testid="product-card-column"
            >
              <div className={styles.productWrapper}>
                {renderProduct(product)}
              </div>
            </div>
          ))}

          {inlineProducts.length > 0 && renderInlineProduct && (
            <div
              className={inlineProductsColumnClassNames}
              data-testid="product-inline-card-column"
            >
              <div className={styles.inlineProductsWrapper}>
                <div className={styles.inlineProductsList}>
                  {inlineProducts.map((product) => (
                    <div key={product.id} className={styles.inlineProduct}>
                      {renderInlineProduct(product)}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

ProductsGrid.defaultProps = {
  productsCountPerRow: ProductsCountPerRow.three,
};
